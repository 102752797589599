"use client";
import { useState } from "react";
import Image from "next/image";
import { FaChevronLeft, FaChevronRight, FaTimes } from "react-icons/fa";

export default function MediaGallery() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const mediaItems = [
    {
      type: "image",
      src: "/images/icsce-dubai-jan-25-event-1.jpeg",
      alt: "Image 1",
    },
    {
      type: "video",
      src: "/images/icsce-dubai-jan-25-event-2.mp4",
    },
    {
      type: "video",
      src: "/images/icsce-dubai-jan-25-event-3.mp4",
    },
    {
      type: "video",
      src: "/images/icsce-dubai-jan-25-event-4.mp4",
      alt: "Image 2",
    },
  ];

  const openModal = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const showPrevious = () => {
    setCurrentIndex((prev) => (prev === 0 ? mediaItems.length - 1 : prev - 1));
  };

  const showNext = () => {
    setCurrentIndex((prev) => (prev === mediaItems.length - 1 ? 0 : prev + 1));
  };

  return (
    <section>
      <div className="bg-gray-50 py-16">
        <div className="max-w-screen-xl mx-auto px-4">
          {/* Section Title */}
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold text-gray-800 mb-2">
              ICSCE DUBAI Jan 25 EVENT
            </h1>
            <p className="text-gray-600">
              Explore our latest videos and images
            </p>
          </div>

          {/* Media Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {mediaItems.map((item, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-lg overflow-hidden cursor-pointer"
                onClick={() => openModal(index)}
              >
                {item.type === "image" ? (
                  <Image
                    src={item.src}
                    alt={item.alt}
                    width={400}
                    height={400}
                    className="w-full h-[600px] object-cover"
                  />
                ) : (
                  <video
                    className="w-full h-[600px] object-cover"
                    src={item.src}
                    muted
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 bg-black   z-50 flex items-center justify-center">
          <button
            className="absolute top-4 right-4 text-white bg-red-500 rounded-full p-2"
            onClick={closeModal}
          >
            <FaTimes size={20} />
          </button>

          {/* Navigation Arrows */}
          <button
            className="absolute left-4 text-white bg-gray-800 rounded-full p-2"
            onClick={showPrevious}
          >
            <FaChevronLeft size={20} />
          </button>
          <button
            className="absolute right-4 text-white bg-gray-800 rounded-full p-2"
            onClick={showNext}
          >
            <FaChevronRight size={20} />
          </button>

          <div className="max-w-4xl w-full max-h-[90%]">
            {mediaItems[currentIndex].type === "image" ? (
              <Image
                src={mediaItems[currentIndex].src}
                alt={mediaItems[currentIndex].alt}
                width={800}
                height={800}
                className="w-full h-auto object-contain"
              />
            ) : (
              <video
                controls
                autoPlay
                className="w-full h-auto object-contain"
                src={mediaItems[currentIndex].src}
              ></video>
            )}
          </div>
        </div>
      )}
    </section>
  );
}
